/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable no-shadow */
/* eslint-disable no-undefined */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable require-jsdoc */
/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import React, { useContext } from 'react'
import Cookies from 'js-cookie'
import styles from './PromoAppointment.module.css'
import * as constants from '../../Utils/constants'
import { paramsObject } from '../../Utils/createUrlParams'
import calculateTimeLeft from '../../Utils/experiments/caculateTimeLeft'
import { CustomerContext } from '../../context/context'
import { getAppointmentVariant } from '../../Utils/appointmentVariant'

const queryParams = paramsObject()
const { Journey_Variant, scenterPromo } = queryParams

/**
 * promoAppointment component
 * @returns {JSX.Element} promoAppointment component
 */
const PromoAppointment = ({ handleCounter }) => {
  const { customer } = useContext(CustomerContext)
  const {
    paymentId,
    Appointment_Variant
  } = customer
  const appointmentVariant = getAppointmentVariant(Appointment_Variant)
  const { appointmentVariantResult } = appointmentVariant
  const cookieTimer = parseInt(Cookies.get('timer'))
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(cookieTimer))

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(cookieTimer))
    }, 1000)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  })

  const timeIsOverText = (setCounter, timeText) => {
    setCounter = false

    return (
      <div className={styles.timerContainer}>
        <div className={styles.timer}>
          <span>
            0
          </span>
          <span>
            0
          </span>
        </div>
        <p>{timeText}</p>
      </div>
    )
  }
  const freeAppointmentVersion = (handleCounter) => {
    handleCounter = true
    return (
      <div className={styles.freeAppointmentContainer}>
        {timeLeft.minutes === undefined && timeLeft.seconds === undefined ? (
          <>
            <p className={styles.freeAppointmentText}>
              Tu tiempo ha expirado. Agenda tu cita y realiza tu pago para
              continuar.
            </p>
            <p className={styles.freeAppointmentTitle}>$30.000</p>
          </>
        ) : (
          <>
            <p className={styles.freeAppointmentText}>
              Agenda tu cita dentro de las próximas
              <span> 24 horas</span>
, para que te salga totalmente
            </p>
            <p className={styles.freeAppointmentTitle}>GRATIS</p>
            <p className={styles.freeAppointmentPrice}>$100.000</p>
          </>
        )}
        <div className={styles.freeAppointmentTimer}>
          <div>
            {timeLeft.minutes ? (
              <div className={styles.timerContainer}>
                <div className={styles.timer}>
                  {timeLeft.minutes.length <= 1 ? <span>0</span> : null}
                  <span>{timeLeft.minutes[0]}</span>
                  {timeLeft.minutes[1] === undefined ? null : (
                    <span>{timeLeft.minutes[1]}</span>
                  )}
                </div>
                <p>Minutos</p>
              </div>
            )
              : timeIsOverText(handleCounter, 'Minutos')
            }
          </div>
          <div className={styles.separatorTimerContainer}>
            <span className={styles.separatorTimer}>:</span>
          </div>
          <div>
            {timeLeft.seconds ? (
              <div className={styles.timerContainer}>
                <div className={styles.timer}>
                  {timeLeft.seconds.length <= 1 ? <span>0</span> : null}
                  <span>{timeLeft.seconds[0]}</span>
                  {timeLeft.seconds[1] === undefined ? null : (
                    <span>{timeLeft.seconds[1]}</span>
                  )}
                </div>
                <p>Segundos</p>
              </div>
            )
              : timeIsOverText(handleCounter, 'Segundos')
            }
          </div>
        </div>
      </div>
    )
  }
  const promoAppointmentVersion = () => (
    <div className={styles.freeAppointmentContainer}>
      <p className={styles.freeAppointmentText}>
        ¡Queremos verte sonreir! Aprovecha nuestra promo y agenda tu cita.
      </p>
      <p className={styles.freeAppointmentTitle}>$30.000</p>
      <p className={styles.freeAppointmentPrice}>$100.000</p>
    </div>
  )

  const redirectFreeAppointmentButton = (evt) => {
    evt.preventDefault()
    window.open(`${appointmentVariantResult.replace('[domain]', 'co')}${paymentId}?Journey_Variant=${Journey_Variant}&scenterPromo=${scenterPromo}`)
  }

  const redirectPromoAppointmentButton = (evt) => {
    evt.preventDefault()
    window.open(`${appointmentVariantResult.replace('[domain]', 'co')}${paymentId}?Journey_Variant=${Journey_Variant}&scenterPromo=${scenterPromo}`)
  }

  const isFreeAppoiment = Journey_Variant
    === constants.FREE_APPOINMENT_VARIANT_MEDELLIN
      || Journey_Variant === constants.FREE_APPOINMENT_VARIANT_BUCARAMANGA

  return (
    <div className={styles.promoAppointmentContainer}>
      <p className={styles.promoAppointmentTitle}>Agenda tu cita de escaneo</p>
      <div className={styles.promoAppointmentContent}>
        <p className={styles.promoAppointmentHeader}>
          {isFreeAppoiment
            ? 'PROMO CITA GRATIS'
            : 'PROMO CITA'}
        </p>
        <p className={styles.promoAppointmentSubtitle}>Escaneo 3D</p>
        {isFreeAppoiment
          ? freeAppointmentVersion(handleCounter)
          : promoAppointmentVersion(handleCounter)}
        {isFreeAppoiment
        && timeLeft.minutes !== undefined
        && timeLeft.seconds !== undefined ? (
            <button onClick={(evt) => redirectFreeAppointmentButton(evt)}>
            Agendar cita gratis
            </button>
          ) : (
            <button onClick={(evt) => redirectPromoAppointmentButton(evt)}>Agendar cita</button>
          )}
      </div>
      <div className={styles.promoAppintmentText}>
        <p className={styles.promoAppointmentTitleMobile}>Agenda tu cita de escaneo</p>
        Durante tu cita, uno de nuestros especialistas realizará el escaneo 3D
        de tu sonrisa, el cual se utilizará para generar tu plan de tratamiento.
        Además, obtendrás una previsualización de tu nueva sonrisa.
      </div>
    </div>
  )
}

export default PromoAppointment
